import styled from "styled-components";
import imageBackContainer from "../../../../assets/image/02_crear_combo/fondoB.png";
import fondo_image from "../../../../assets/image/01_inicio_y_registro/01_inicio_fondo_con_iconos.jpg";
import btn_image from "../../../../assets/image/02_crear_combo/02_crear_combo_boton_descargar.png";
import btn_image_hover from "../../../../assets/image/02_crear_combo/02_crear_combo_boton_descargar_hover.png";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

export const GridBinding = styled.div`
  width: 15vw;
  display: flex;
  flex: 1;
  text-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-position: center;
  background-size: contain;
  background-repeat: repeat;
  border-left: 1px solid ${(props) => props.theme.colors.border};
  background-image: url(${imageBackContainer});
  background-color: ${(props) => props.theme.colors.secondary};
`;

export const BackImageComboRect = styled.img`
  width: 21vw;
  //margin-top: 2em;
  // margin-bottom: 2em;
`;

export const ContainerDescarga = styled.div`
  display: flex;
  flex: 8;
  height: 90%;
  // width: 100%;
  flex-direction: column;
  @media (orientation: portrait) {
    flex: 1;
    height: unset;
    overflow: scroll;
  }
`;

export const SubContainerDescarga = styled.div`
  display: flex;
  flex: 0.93;
  flex-direction: row;
  @media (orientation: portrait) {
    flex-direction: column;
  }
`;

export const GridLeft = styled.div`
  background-image: url(${fondo_image});
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0.6;
  width: 60%;
  padding: 0 50px 0 0px;
  @media (orientation: portrait) {
    flex: 1;
    width: 100%;
    padding-bottom: 10px;
  }
`;

export const ContainerImages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ImageText = styled.img`
  width: 70%;
`;

export const ImageSmile = styled.img`
  width: 20%;
  margin-left: 30px;
`;

export const ImageIcon = styled.img`
  margin-right: 30px;
  width: 52px;
`;

export const ContainerIcons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  padding-left: 50px;
`;

export const GridButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-left: 100px;
`;

export const BtnDescarga = styled.a`
  --id: BtnDescarga;
  width: 8em;
  background-image: url(${btn_image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  :hover {
    background-image: url(${btn_image_hover});
    background-repeat: no-repeat;
  }
  @media (orientation: portrait) {
    width: 6em;
  }
`;

export const Canvas2D = styled.canvas`
  width: 21vw;
  margin-top: 2em;
  margin-bottom: 2em;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.4;
  background-color: ${(props) => props.theme.colors.secondary};
  justify-content: center;
  align-items: center;
`;

export const Canvas2DDescarga = styled.canvas`
  flex: 1;
  height: ${(props) => props.size};
  position: absolute;
  left: ${(props) => (props.left ? props.left : "30.4%")};
  top: ${(props) => props.top};
  z-index: 1;
`;

export const BackButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  outline: none;
  font-family: GilroyMedium;
  font-size: large;
  align-items: center;
  color: ${(props) => props.theme.colors.button};
  margin: 10px;
  height: 30px;
  border: 1px solid ${(props) => props.theme.colors.border};
  background: ${(props) => props.theme.colors.background};
  &:hover {
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.background};
  }
`;

export const HomeButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  outline: none;
  font-family: GilroyMedium;
  font-size: large;
  align-items: center;
  color: ${(props) => props.theme.colors.button};
  margin: 10px;
  height: 30px;
  width: 200px;
  border: 1px solid ${(props) => props.theme.colors.border};
  background: ${(props) => props.theme.colors.background};
  &:hover {
    color: ${(props) => props.theme.colors.background};
    background: ${(props) => props.theme.colors.primary};
  }
`;
